import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AppRoutes } from '../../models/core/enums/app-routes.enum';
import { AuthenticateService } from '../../services/authenticate.service';
import { CurrentUser } from '../../models/user/current-user';
import { NavigationEnd, Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { StorageKeys } from '../../models/core/enums/storage-keys.enum';
import { Subscription } from 'rxjs';
import { SubscriptionStatus } from '../../models/subscription/enums/subscription-status.enum';
import { UiContentService } from '../../services/ui-content.service';
import { UserService } from '../../services/user.service';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  appRoutes = AppRoutes;
  currentUser: CurrentUser = null;
  isCollapsed = true;
  isLoggedIn = false;
  subscriptions: Subscription[] = [];
  id: any;
  contact: any;
  password: any;
  profilePicUrl:any;
  userProfilePicUrl:any;
  user_Name: any;
  isUserLoggin: string;
  userLocalData: any;
  // data:any;
  // userID:any;



  constructor(private analytics: AngularFireAnalytics,
    private authenticateService: AuthenticateService,
    private elementRef: ElementRef,
    private router: Router,
    private sharedService: SharedService,
    private uiContentService: UiContentService,
    private userService: UserService,
    private httpClient: HttpClient) {

      try {

        this.currentUser = JSON.parse( this.sharedService.appStorage.getItem( StorageKeys.USER_DETAILS ) );
        this.profilePicUrl =this.currentUser.profileImageId;
          this.user_Name =this.currentUser.fullName;
          console.log("Username_mob", this.user_Name);


        if(sessionStorage.getItem('socialData') != null){
          this.currentUser = JSON.parse(sessionStorage.getItem('socialData'));
          this.user_Name = this.currentUser.fullName;
          console.log("Username_social", this.user_Name);
          console.log("socialUsrData", this.currentUser.email);
        }

      } catch (e) {

        // this.sharedService.showWarningMessage( `Unable to parse user details!!` );
      }

    // sessionStorage.setItem('id', this.id);

    // this.data = JSON.parse(sessionStorage.getItem(StorageKeys.USER_DETAILS));
    // this.userID = this.data.user_id;
    // console.log(this.userID);


    const subscription = this.sharedService.loggedInState
      .subscribe(state => {
        if (state) {
          this.fetchForCurrentUserDetails(this.id, this.contact, this.password);
        }
      });

    this.subscriptions.push(subscription);

    // if (sessionStorage.getItem(StorageKeys.USER_DETAILS) !== null) {

    //   this.isLoggedIn = true;
    //   this.fetchForCurrentUserDetails(this.id, this.contact, this.password);
    // }

    const subscription2 = this.router.events
      .subscribe(event => {

        if (event instanceof NavigationEnd) {
          this.fetchForServerStatus();
        }
      });

    this.subscriptions.push(subscription2);
  }

  ngOnInit(): void {

    this.isUserLoggin = localStorage.getItem("is_LoggedIn");
    if(this.isUserLoggin=="true"){
      this.userLocalData = JSON.parse(localStorage.getItem("k_det"));

      this.fetchForCurrentUserDetails(this.userLocalData.id, this.userLocalData.contact, this.userLocalData.password);

    }
    else{
     // this.router.navigate([AppRoutes.HOME]).then();
    }


    this.getIPAddress();

    // const ip = sessionStorage.getItem('ip');
    // if(ip == undefined || ip == ''){
    //   window.location.reload()
    // }

    this.toggleHeader();
  }

  // Toggle Header on Scroll
  // ----------------------------------------------------------------
  toggleHeader(): void {

    window.onscroll = () => {

      const navBar = document.getElementById('main-nav');

      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {

        navBar.classList.add('bg-dark');
      } else {

        navBar.classList.remove('bg-dark');
      }

      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 1)) {

        this.sharedService.updateBottomReachedState(true);
      }
    }
  }

  // Toggle Menu On Header
  // ----------------------------------------------------------------
  toggleMenu(): void {

    this.isCollapsed = !this.isCollapsed;
  }

  // Login Access Update
  // ----------------------------------------------------------------
  loginAccessUpdate(): void {

    const subscription = this.authenticateService.loginAccessUpdate(SharedService.fetchForBrowserDetails())
      .subscribe((data2) => {
        console.log('Updated')
        sessionStorage.setItem('usrid', data2.id);
      },
        error => console.error(error));

    this.subscriptions.push(subscription);
  }

  // Fetch For Current User Profile Details
  // ----------------------------------------------------------------
  fetchForCurrentUserDetails(id: any, conatact: string, password: string): void {

    this.userService.fetchForCurrentUserDetails(id, conatact, password)
      .subscribe(currentUser => {
        const sessionVal = sessionStorage.getItem('k_det');
        // this.loginAccessUpdate();
        // console.log('###=>',sessionVal);
        this.isLoggedIn = true;
        this.currentUser = currentUser;
        if (sessionVal == undefined) {
          this.sharedService.appStorage.setItem(StorageKeys.USER_DETAILS, JSON.stringify(currentUser[0]));
        }

        this.analytics.setUserId(this.currentUser.id, { global: true }).then();

        let subscription = '';
        let daysLeft = '';

        if (this.currentUser.consumerSubscription === null) {
          subscription = 'fresh-never-subscribed';
          daysLeft = '-999';
        } else {
          // subscription = `${this.currentUser.consumerSubscription.subscriptionPackage.titlePlatformSlug}-${this.currentUser.consumerSubscription.subscriptionPackage.listedPrice}`;

          // if (this.currentUser.consumerSubscription.subscriptionStatus === SubscriptionStatus.EXPIRED) {
          //   daysLeft = '-888';
          // } else {
          //   const todayDate = moment();
          //   const packageExpiryDate = moment(this.currentUser.consumerSubscription.subscriptionEndDateTime);
          //   daysLeft = packageExpiryDate.diff(todayDate, 'days').toString();
          // }
        }

        this.analytics.setUserProperties({ subPackage: subscription }).then();
        this.analytics.setUserProperties({ days: daysLeft }).then();

        // this.router.navigate([ AppRoutes.HOME ]).then();
      }, error => {
        console.error(error);
        this.sharedService.appStorage.clear();
      });
  }

  // Logout Current User from OAuth2
  // ----------------------------------------------------------------
  logoutCurrentUserFromOAuth(): void {

    const message = `You want to Sign Out from Pranadh`;

    this.sharedService.openConfirmationPopUp(message)
      .then(status => {

        if (status) {
          this.sharedService.updateSpinnerState(true);

          const subscription = this.authenticateService.OAuthLogout()
            .pipe(finalize(() =>
             this.logout()
             ))
            .subscribe();

          this.subscriptions.push(subscription);
        }
      })
      .catch(error => console.error(error));
  }

 

  // Logout User and Clear Local Storage
  // ----------------------------------------------------------------
  logout(): void {

    this.currentUser = null;
    this.isLoggedIn = false;
    this.sharedService.appStorage.clear();
    this.sharedService.updateLoginState(false);

    this.getIPAddress();
    this.router.navigate([AppRoutes.HOME]).then();
    this.sharedService.updateSpinnerState(false);
    localStorage.setItem("is_LoggedIn", "false");
    localStorage.removeItem('k_det');
    localStorage.removeItem('user_id');
    localStorage.removeItem('regID');
    localStorage.removeItem('socialData');
    localStorage.removeItem('login_token');
    // sessionStorage.setItem('socialData',null)
    // sessionStorage.setItem('k_det',null)

  }

  getIPAddress(): void {
    this.httpClient.get("https://api64.ipify.org/?format=json").subscribe((res:any)=>{
      sessionStorage.setItem('ip', res.ip);
      localStorage.setItem('ipAddress', res.ip);
    });

  }
  // Fetch For Server Status
  // ----------------------------------------------------------------------------------
  fetchForServerStatus(): void {

    const subscription = this.uiContentService.fetchForServerStatus()
      .subscribe(status => {

        if (!status.web.enabled) {
          this.router.navigate([AppRoutes.MAINTENANCE]).then();
        }
      }, error => console.error(error));

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {

    SharedService.unsubscribe(this.subscriptions);
  }
}
