import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';

import { AppStoreDialogModule } from './dialogs/app-store-dialog/app-store-dialog.module';
import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { AvatarModule } from 'ngx-avatar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { intersectionObserverPreset, LazyLoadImageModule } from 'ng-lazyload-image';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule } from 'angular-oauth2-oidc';
import { PageSpinnerModule } from './components/page-spinner/page-spinner.module';
import { PushPermissionModule } from './dialogs/push-permission/push-permission.module';
import { SharedModule } from './shared/shared.module';

import { HttpClientInterceptor } from './shared/http-client-interceptor';
import { UiContentService } from './services/ui-content.service';
import { UserService } from './services/user.service';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { CookieService } from 'ngx-cookie-service';

import { StateOptionsModule } from './dialogs/state-options/state-options.module';
import { ComingModule } from './pages/coming/coming.module';


export function provideConfig() {
  return new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider('569661193392-nj11ggtluk409l955dbn770jncb2grpr.apps.googleusercontent.com')    //For LocalHost
      // provider: new GoogleLoginProvider('394161949677-aetjkvtrt684v4fm1p5lhvo7egbj1n0o.apps.googleusercontent.com')  //For Testing Domain
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider('1125007921642260')
    }
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SafeHtmlPipe,
  ],
  imports: [
    AppStoreDialogModule,
    AvatarModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbCollapseModule,
    StateOptionsModule,
    ComingModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          '/secure',
          '/token/logout',
          '/consumer/media/token/isAllowed',
          '/user/changeProfileConsumer',
          '/consumer/updateFCM/secure',
          '/loginaccess/insertUserLoginAccess/secure'
        ],
        sendAccessToken: true
      }
    }),
    PageSpinnerModule,
    PushPermissionModule,
    SharedModule.forRoot(),
    SocialLoginModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    },
    UiContentService,
    UserService,
    CookieService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
}
